import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styles from './Samples.module.scss'
import Codepen from '../../assets/svg/Codepen'

const Portfolio = () => {
  const [hover, setHover] = useState('')

  const handleToggle = () => {
    setHover(!hover)
  }

  const data = useStaticQuery(graphql`
    query {
      imageShirt1: file(relativePath: { eq: "sample_shirt1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShirt2: file(relativePath: { eq: "sample_shirt2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShirt3: file(relativePath: { eq: "sample_shirt3.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShirt3on: file(relativePath: { eq: "sample_shirt3on.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaXmas: file(
        relativePath: { eq: "portfolio-hotahika-xmas.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaFathersday: file(
        relativePath: { eq: "portfolio-hotahika-fathersday.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaAutumn: file(
        relativePath: { eq: "portfolio-hotahika-autumn.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOisixEasy: file(relativePath: { eq: "portfolio-oisix-easy.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOisixLogo: file(relativePath: { eq: "portfolio-oisix-logo.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOisixHome: file(relativePath: { eq: "portfolio-oisix-home.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={styles.root}>
      <div className="container-fluid">
        <h1 className={styles.heading}>Ayana Osawa's Design Samples</h1>
      </div>

      <div className={`container-fluid ${styles.codepen}`}>
        - Check out my animation work on{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://codepen.io/ayaos`}
        >
          <Codepen /> Codepen
        </a>
      </div>

      <div className="container-fluid">
        <h2 className={styles.sHeading}>Personal project T-shirt design</h2>
      </div>
      <div className={`container-fluid ${styles.items}`}>
        <div className={styles.item}>
          <Img
            fluid={data.imageShirt1.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageShirt2.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div
          className={`${styles.item} ${hover ? styles.active : ''}`}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
        >
          <Img
            fluid={data.imageShirt3.childImageSharp.fluid}
            alt=""
            className={styles.initialImage}
          />
          <Img
            fluid={data.imageShirt3on.childImageSharp.fluid}
            alt=""
            className={styles.hoverImage}
          />
        </div>
      </div>

      <div className="container-fluid">
        <h2 className={styles.sHeading}>Banner design work as a freelancer</h2>
      </div>
      <div className={`container-fluid ${styles.items}`}>
        <div className={styles.item}>
          <Img
            fluid={data.imageHotahikaXmas.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageHotahikaFathersday.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageHotahikaAutumn.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageOisixEasy.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageOisixLogo.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
        <div className={styles.item}>
          <Img
            fluid={data.imageOisixHome.childImageSharp.fluid}
            alt=""
            className={styles.picks__image}
          />
        </div>
      </div>

      <div className="container-fluid">
        <Link to="/" className={styles.button}>
          ← Go to home
        </Link>
      </div>
    </div>
  )
}
export default Portfolio
