import React from 'react'
import Layout from '../components/Layout/Layout'
import Samples from '../components/Samples/Samples'

export default () => {
  return (
    <Layout>
      <Samples />
    </Layout>
  )
}
